import htmx from "htmx.org";
import initModals from "./initModals";
import initNavbarBurgers from "./initNavbarBurgers";
import initNotifications from "./initNotifications";
import initTinymce from "./initTinymce";

initNavbarBurgers();
initModals();
initNotifications();
initTinymce();
